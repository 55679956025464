
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-quick-view-content {
  background-color: $color-neutral-three;
  align-content: start;
  overflow: hidden;

  &__wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__image-container {
    display: block;
    width: 100%;
    overflow: hidden;
    padding-top: 125%;
    position: relative;
    background-color: $color-background-card-image-container;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}

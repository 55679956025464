
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-inbox-row-label {
  display: flex;
  align-items: center;
  font-size: $font-size-tiny;
  font-weight: $font-weight-semibold;
  color: $shark-grey-5;

  &__icon {
    margin-right: 4px;
    font-size: 25px;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.DropzoneInvalidModal {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-left: 10px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    padding: 15px;
    font-size: 18px;
  }

  &__footer {
    padding: 15px;
  }

  &__fileTypeListTitle {
    margin-bottom: 5px;
  }

  &__fileTypeList {
    list-style: initial;
    padding-left: 30px;
  }
}

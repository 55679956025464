
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-performer-contact {
  position: relative;

  .c-alert-message__additional-content {
    padding-top: 10px;
    padding-bottom: 25px;
    border-bottom: 1px solid $medium-grey-3;
  }

  &__spinner {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 15;
    background-color: rgba($color-neutral-three, 0.4);
  }
}

.c-performer-contact-form {
  padding: unset;

  &__additional-text {
    margin-bottom: 25px;
    font-size: $font-size-small;
  }
  &__consent {
    margin-bottom: 25px;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-tabs {
  position: relative;
  height: 50px;

  &__container {
    position: absolute;
    overflow: hidden;
    border-bottom: 1px solid $color-border-tab;
    width: 100%;
    height: 50px;
  }

  &__set {
    position: absolute;
    display: flex;
    transition: 0.3s;
  }

  &__arrow {
    position: absolute;
    height: 24px;
    width: 49px;
    z-index: 1;
    background-color: $white;
    top: 13px;
    cursor: pointer;
    fill: $color-neutral-one;
    transition: 0.2s;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      fill: $color-brand-one;
    }

    &:focus {
      fill: $color-accent-one;
    }
  }

  &__left {
    left: -15px;
    transform: rotate(90deg);
    box-shadow: 0 -6px 5px -2px rgba($color-neutral-one, 0.2);
  }

  &__right {
    right: -15px;
    transform: rotate(-90deg);
    box-shadow: 0 -6px 5px -2px rgba($color-neutral-one, 0.2);
  }

  &__invisible {
    display: none;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-performer-search-custom {
  position: relative;

  .c-input-label {
    display: none;
  }

  .c-search__spinner {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: $color-neutral-one;
  }

  &__search-more {
    font-weight: $font-weight-semibold;
    color: $color-text-alternative-link;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  &__no-results,
  &__search-more {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .c-input-wrapper input,
  .c-autocomplete {
    background: #fff;
  }

  .c-autocomplete__item {
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover,
    &:focus {
      background: $color-neutral-three;
    }
  }
}

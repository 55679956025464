
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
a.c-step-tracker-item {
  cursor: pointer;
  outline: 0;
  color: $color-text-step-tracker-primary;

  &:hover,
  &:focus {
    color: $color-text-default;

    .c-step-tracker-item {
      &__title,
      &__subtitle {
        text-decoration: underline;
      }
      &__label {
        box-shadow: 1px 1px 2px 0 $color-shadow-step-tracker-item-hover;
      }
    }
  }
}

.c-step-tracker-item {
  $c: c-step-tracker-item;

  width: auto;
  color: $color-text-step-tracker-primary;

  @include breakpoint(small) {
    padding: 5px 0;
  }

  &--active {
    color: $color-text-default;

    .#{$c} {
      &__label {
        background-color: $color-brand-two;
        color: $white;
      }
    }
  }

  &--completed {
    .#{$c} {
      &__label {
        background-color: $color-brand-three;
        font-size: $font-size-medium;
        color: $white;
        font-weight: $font-weight-semibold;
      }
    }
  }

  &__inner {
    height: 100%;
    padding: 3px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__label {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: $color-neutral-three;
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    line-height: 1;
    transition: box-shadow 0.2s ease;
  }

  &__content-container {
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.1s ease;

    @include breakpoint(small) {
      display: none;
    }
  }

  &__content {
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    $title-line-height: 18px;

    max-height: $title-line-height * 2;
    font-size: $font-size-small;
    line-height: $title-line-height;
    font-weight: $font-weight-semibold;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  &__title {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__subtitle {
    -webkit-box-orient: vertical;
    font-size: $font-size-tiny;
    line-height: 15px;
  }
}

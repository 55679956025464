
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-localization-organism {
  &__spinner {
    padding: 45px;
  }
}
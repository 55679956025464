
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.direct-debit-guarantee-text {
  p {
    @apply mb-5 text-color-neutral-one;
  }
}

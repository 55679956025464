
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$toggle-button-width: 50px;

@mixin datepicker-font-override {
  font-family: $font-primary;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
}

@mixin datepicker-border {
  border: 1px solid map-get($grey, 90);
}

@mixin year-decade-top-level-override {
  border: none;
  background-color: $color-background-datepicker;
  padding-top: 14px;
}

@mixin year-decade-grid-override {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 14px;

  .react-calendar__tile {
    background-color: $white;
    max-width: unset !important;

    &:focus,
    &:hover {
      background-color: $color-background-datepicker-tile-hover;
    }

    &:focus {
      text-decoration: underline;
    }
  }

  .react-calendar__tile--hasActive {
    font-weight: $font-weight-bold;
    background-color: $color-background-datepicker-tile-selected;
  }
}

@mixin year-decade-button-override {
  padding: 12px 0 15px 0;
  border: 1px solid $color-outline-inputs;
  font-weight: $font-weight-semibold;
}

.c-simple-datepicker {
  width: 100%;
  margin-bottom: 40px;

  &__input {
    width: 100%;
    display: flex;
    align-items: flex-end;

    .c-input {
      display: inline-block;
      flex-basis: calc(100% - #{$toggle-button-width});

      &-wrapper {
        margin-bottom: 0;
      }

      .c-input-wrapper__validation {
        width: calc(100% + #{$toggle-button-width});
      }
    }

    &__toggle {
      flex-basis: $toggle-button-width;
      height: $input-height;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: $color-background-button-primary;
      color: $color-text-button-primary;

      &:hover {
        background: $color-background-button-primary-hover;
        color: $color-text-button-primary-hover;
      }
    }
  }

  &__calendar-positioner {
    @include breakpoint(medium_up) {
      position: relative;
      width: 100%;
    }

    @include breakpoint(small) {
      margin-left: 100%;
    }
  }

  &__calendar-container {
    z-index: 1;

    @include breakpoint(small) {
      position: absolute;
      width: calc(100vw - 20px);
      max-width: 460px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__arrow {
    @include arrow("top", $color-background-datepicker, 25px);
    margin-bottom: -5px;

    @include breakpoint(medium_up) {
      position: absolute;
      right: 0;
      z-index: 2;
    }

    @include breakpoint(small) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__calendar {
    background: $color-background-datepicker;
    padding: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;

    @include breakpoint(medium_up) {
      width: calc(100vw - 20px);
      position: absolute;
      max-width: 460px;
      top: 25px;

      &.align-right {
        right: 0;
      }
    }

    .react-calendar {
      @include datepicker-border;
      width: 100%;

      &.year,
      &.decade {
        border: none;

        .react-calendar__navigation {
          @include datepicker-border;
        }
      }

      &__tile {
        @include datepicker-font-override;

        &:focus,
        &:hover {
          background-color: $color-background-datepicker-tile-hover;
        }

        &:focus {
          text-decoration: underline;
        }

        &.react-calendar__tile--active,
        &.react-calendar__tile--hasActive {
          background-color: $color-background-datepicker-tile-selected;
          color: $color-text-default;
        }
      }

      &__navigation {
        margin-bottom: 0;

        &__label {
          @include datepicker-font-override;
        }

        .icon-chevron {
          font-size: 32px;
          color: $color-brand-one;
        }

        &__next-button .icon-chevron {
          transform: rotateY(180deg);
        }

        & button:enabled {
          &:focus,
          &:hover {
            background-color: $color-background-datepicker-tile-hover;
          }
        }

        & button:disabled {
          background-color: $color-background-datepicker;

          .icon-chevron {
            color: map-get($grey, 70);
          }
        }
      }

      &__month-view {
        &__weekdays__weekday {
          @include datepicker-font-override;
          text-transform: capitalize;
          text-decoration: none;
          & > abbr[title] {
            text-decoration: none;
          }
        }

        &__days__day--weekend {
          color: $color-text-default;
        }
        &__days__day--neighboringMonth {
          color: map-get($grey, 72);
        }

        .react-calendar__tile--now {
          border: 1px solid $light-grey-2;
        }
      }

      &__year-view {
        @include year-decade-top-level-override;

        &__months {
          @include year-decade-grid-override;

          &__month {
            @include year-decade-button-override;
          }
        }
      }

      &__decade-view {
        @include year-decade-top-level-override;

        &__years {
          @include year-decade-grid-override;

          &__year {
            @include year-decade-button-override;
          }
        }
      }
    }
  }

  &.c-simple-datepicker__disabled {
    .c-simple-datepicker__input__toggle {
      pointer-events: none;
      background-color: $color-background-button-primary-disabled;
      svg #dateicon {
        fill: $color-icon-datepicker-toggle-disabled;
      }
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-input-search-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 30px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: $color-text-disabled;

  &:focus {
    outline: none;
  }

  &__container {
    position: relative;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-text-ellipsis {
  word-break: break-word;
  &__hidden-calc-area {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    pointer-events: none;
  }
}

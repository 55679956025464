
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
@mixin formControlsGrid($colWidth) {
  @for $i from 1 through 4 {
    &:nth-last-child(#{$i}) {
      $colStart: $grid-columns + 1 - $i * $colWidth;
      grid-column-start: $colStart;
      grid-column-end: $colStart + $colWidth;
    }
  }
}

.c-section-controls {
  @include grid-content-area;
  margin-top: 20px;
  grid-template-columns: repeat($grid-columns, 1fr);

  @include breakpoint(small) {
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }

  & > * {
    @include breakpoint(large) {
      @include formControlsGrid(3);
    }

    @include breakpoint(medium) {
      @include formControlsGrid(4);
    }
  }
}

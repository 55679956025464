
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$duration: 1.5s;

@keyframes loading-indicator-rotator {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-indicator-dash {
  0% {
    stroke-dasharray: 1px, 80px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 60px, 80px;
    stroke-dashoffset: -4px;
  }
  100% {
    stroke-dasharray: 60px, 80px;
    stroke-dashoffset: -50px;
  }
}

.loading-indicator {
  $c: loading-indicator;
  line-height: 0;

  &--primary {
    .#{$c}__spinner-path {
      stroke: $white;
    }
  }

  &--secondary {
    .#{$c}__spinner-path {
      stroke: $color-brand-one;
    }
  }

  &--tertiary {
    .#{$c}__spinner-path {
      stroke: $color-brand-two;
    }
  }

  &__inner {
    display: inline-flex;
    align-items: center;
  }

  &__spinner-container {
    margin-top: -4px;
    margin-bottom: -4px;
  }

  &__spinner-container + .loading-indicator__text {
    margin-left: 10px;
  }

  &__spinner {
    animation: loading-indicator-rotator $duration linear infinite;
  }

  &__spinner-path {
    stroke-dasharray: 30px, 80px;
    stroke-dashoffset: 0px;
    transform-origin: center;
    animation: loading-indicator-dash $duration ease-in-out infinite;
  }

  &__text {
    font-size: $font-size-AA-large;
    font-weight: $font-weight-semibold;
    line-height: $font-size-base;
  }
}

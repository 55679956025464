
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-section-container {
  background-color: $white;
  border: solid 8px $color-neutral-three;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 14px 15px;
    border-bottom: 1px solid rgba($color-neutral-one, 0.25);

    &-text {
      color: $color-neutral-one;
      font-size: $font-size-large;
    }

    &-controls {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-self: center;
      gap: 8px;

      @include breakpoint(medium) {
        gap: 0;
      }
    }
  }

  &--secondary {
    background-color: $color-neutral-three;
    border: solid 8px $white;
  }

  &--shadowed {
    background-color: $white;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 15px 0 transparentize($color-neutral-one, 0.8);

    @include breakpoint(medium_down) {
      box-shadow: 0 -15px 15px -15px transparentize($color-neutral-one, 0.8),
        0 15px 15px -15px transparentize($color-neutral-one, 0.8);
    }
  }
}

.c-section-container-control {
  margin-left: 23px;

  @include breakpoint(medium_down) {
    color: $color-text-alternative-link;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$filter-drawer-transition-time: 0.5s;
$filter-drawer-width: 500px;
$filter-drawer-control-size: 50px;

:export {
  transitionTime: strip-unit($filter-drawer-transition-time);
}

@mixin filterDrawerTransition {
  transition: width $filter-drawer-transition-time;
}

.c-filter-drawer {
  position: fixed;
  background-color: transparentize($color-neutral-one, 0.6);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: $filter-drawer-zindex;
  transform: translateZ(#{$filter-drawer-zindex}px);

  @include breakpoint(small) {
    background-color: unset;
  }

  &__container {
    @include filterDrawerTransition;
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    right: 0;
    width: 0;
    background-color: $white;
    box-shadow: 0 0 15px 0 transparentize($color-neutral-one, 0.8);

    &:focus {
      outline: 0;
    }

    &--active {
      width: $filter-drawer-width;
      @include breakpoint(small) {
        width: 100%;
      }
    }
  }

  &__wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: $filter-drawer-width;
    @include breakpoint(small) {
      width: 100vw;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-basis: auto;
    -webkit-overflow-scrolling: touch;
    padding: 20px;
    @include breakpoint(small) {
      padding: 15px;
    }
  }

  &__header {
    min-height: $filter-drawer-control-size;
    flex-basis: $filter-drawer-control-size;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__close {
    flex-basis: $filter-drawer-control-size;
    width: $filter-drawer-control-size;
    font-size: 32px;
  }

  &__back {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: $filter-drawer-control-size;
    line-height: $filter-drawer-control-size;
    flex-basis: $filter-drawer-control-size;
    min-width: $filter-drawer-control-size;
    color: $color-brand-one;
    font-size: 36px;
    text-align: center;
    cursor: pointer;
    &:hover,
    &:focus {
      color: $color-neutral-one;
    }
  }

  &__title {
    flex-grow: 1;
    overflow: hidden;
    margin: 0 10px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    &--list {
      margin: 0 10px 0 60px;
    }
  }

  &__buttons-set {
    padding: 15px;
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: 1fr 1fr;
    @include breakpoint(small) {
      padding: 10px 15px;
      grid-column-gap: 10px;
    }
  }
}

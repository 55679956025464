
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-expanding-area {
  $c: c-expanding-area;

  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
  will-change: height;

  &--primary {
    .#{$c}__content,
    .#{$c}__content-arrow {
      background-color: $color-background-expanding-area-primary;
    }

    @include backgroundSecondaryOverrides;
  }

  &--secondary,
  .g-bg-secondary &,
  .c-input-group--secondary & {
    .#{$c}__content,
    .#{$c}__content-arrow {
      background-color: $color-background-expanding-area-secondary;
    }

    @include backgroundSecondaryOverrides;
  }

  &--tertiary,
  .g-bg-tertiary &,
  .c-input-group--tertiary & {
    color: $color-text-form-secondary;

    .#{$c}__content,
    .#{$c}__content-arrow {
      background-color: $color-background-expanding-area-tertiary;
    }

    @include backgroundSecondaryOverrides;
  }

  &__content-container {
    padding-top: 9px;
  }

  &__content-arrow {
    width: 20px;
    height: 20px;
    margin: 0 28px -10px;
    display: block;
    transform: rotate(45deg);
  }

  &__content {
    padding: 20px 15px;
  }
}

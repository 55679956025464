
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
%nav-button {
  position: absolute;
  bottom: 5px;
  color: $white;
  background-color: $color-brand-one;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    background-color: $color-brand-two;
  }
}

.c-quick-view-card {
  position: relative;

  &__wrapper {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 0px 16px -6px;
  }

  &__content {
    margin-left: -15px;
    margin-right: -15px;

    @include breakpoint(medium) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  &__name {
    font-size: $font-size-base;
    color: $color-neutral-one;
    display: inline-block;
    font-weight: $font-weight-semibold;
  }

  &__close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 6px;
    right: 7px;
    font-size: $font-size-icon-default;
    cursor: pointer;
    z-index: 1;
    color: $shark-grey-5;
  }

  &__prev-card {
    &.icon-chevronleft {
      line-height: 46px;
      font-weight: $font-weight-semibold;
      padding-right: 3px;
    }

    @extend %nav-button;
    left: 5px;
  }

  &__next-card {
    &.icon-chevronright {
      line-height: 46px;
      font-weight: $font-weight-semibold;
      padding-left: 3px;
    }

    @extend %nav-button;
    right: 5px;
  }

  $maxColumns: 12;
  @for $totalColumns from 1 through $maxColumns {
    @for $selectedCard from 1 through $totalColumns {
      &#{&}--position-#{$selectedCard}-#{$totalColumns} {
        &:after {
          $columnwidthInRem: calculate-rem($columnGridGapOnLarge);
          left: calc(
            ((100% + #{$columnwidthInRem}) / #{$totalColumns}) *
              (#{$selectedCard} - 0.5) -
              #{$columnwidthInRem} /
              2
          );
          @include breakpoint(medium) {
            $columnwidthInRem: calculate-rem($columnGridGapOnMedium);
            left: calc(
              ((100% + #{$columnwidthInRem}) / #{$totalColumns}) *
                (#{$selectedCard} - 0.5) - #{$columnwidthInRem} / 2
            );
          }
          margin-left: 16px; // This is an offset fudge, because the arrow is made from an element that is rotated
        }
      }
    }
  }

  @include breakpoint(medium_up) {
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      top: 1px;
      left: 91.5%;
      box-sizing: border-box;
      border: 0.6em solid $color-neutral-one;
      border-color: transparent transparent $white $white;
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
    }
  }

  @include breakpoint(small) {
    display: flex;
    flex-grow: 1;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;

    &__wrapper {
      display: flex;
      flex-grow: 1;
    }

    &__content {
      display: flex;
      flex-grow: 1;
      background-color: $color-neutral-three;
    }
  }
}

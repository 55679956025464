
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-notification-toast {
  padding: 5px;
  border-radius: 2px;
  position: relative;
  box-shadow: 0 0 3px 1px $color-shadow-notification-toast;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;

  &--success {
    background-color: $color-background-notification-toast-success;

    .c-notification-toast__title-icon {
      color: $color-brand-three;
    }
  }

  &--error {
    background-color: $color-background-notification-toast-error;

    .c-notification-toast__title-icon {
      color: $cardinal-red;
    }
  }

  &--absolute {
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(100% + 5px);
  }

  &--fixed {
    z-index: $important-zindex;
    position: fixed;
    width: 290px;
    height: 50px;
    left: calc(50% - 145px);
    top: calc(50% - 25px);
    display: flex;
    align-items: center;
  }

  &--active {
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
  }

  &__title {
    padding-right: 20px;
    display: flex;
    align-items: center;
  }

  &__title-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    font-size: $font-size-medium;
  }

  &__title-text {
    font-size: $font-size-medium;
    color: $color-text-notification-toast-title;

    p:not([class]) {
      margin: 0;
      font-size: inherit;
      color: inherit;
    }

    a:not([class]) {
      font-size: inherit;
    }
  }

  &__control {
    @include removeDefaultButtonStyling;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: $white;
    outline: none;
  }
}

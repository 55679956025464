
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-record-with-action-controls {
  border-bottom: dashed 1px $light-grey-2;
  padding: 7px 10px;

  &:first-child {
    border-top: dashed 1px $light-grey-2;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 10px;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    min-height: 37px;
  }

  &__control {
    @include removeDefaultButtonStyling;
    color: $color-brand-one;
    font-size: 37px;
    margin-left: 10px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $color-accent-one;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.credits-grid {
  overflow: hidden;
  transition: 0.5s;

  &__body {
    @include breakpoint(small) {
      padding-top: 10px;
    }
  }

  &__body-row {
    background: transparent;

    &:nth-child(2n + 1) {
      background-color: $white;
    }
  }

  &__body-cell {
    font-size: 14px;
    padding: 5px 0;
    height: 100%;
    word-break: break-word;

    @include breakpoint(small) {
      grid-column: span 1 / auto !important;
      &:first-child {
        grid-column: span 2 / auto !important;
      }
    }

    &:nth-child(2) {
      font-weight: $font-weight-semibold;
    }

    & span {
      display: inline-block;
    }
  }

  &__cell-label {
    display: none;

    @include breakpoint(small) {
      display: block;
      font-weight: $font-weight-semibold;
    }
  }

  &__credit-title {
    font-size: 16px;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    padding-left: 7px;

    @include breakpoint(small) {
      padding-left: 0;
    }
  }

  &__body-row {
    min-height: unset;
    border: unset;

    @include breakpoint(large) {
      grid-gap: 20px;
    }

    @include breakpoint(medium) {
      grid-gap: 20px;
    }

    @include breakpoint(small) {
      grid-template-columns: repeat(2, 1fr) !important;
      padding-left: 7px;
      grid-gap: 5px;
    }
  }

  &__header {
    @include breakpoint(small) {
      display: none;
    }
  }

  &__header-cell {
    background: transparent;
    border-left: none;
  }

  &__header-label {
    font-size: 14px;
    height: 34px !important;
    font-weight: $font-weight-semibold;
    padding: 0;
  }

  &__header-row {
    @include breakpoint(large) {
      grid-gap: 20px;
    }

    @include breakpoint(medium) {
      grid-gap: 20px;
    }
  }

  &__header-title {
    color: $color-neutral-one;
  }
}

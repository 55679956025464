
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-dropzone-uploader {
  height: 100%;

  &__dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: dashed 1px $light-grey-2;
    color: $color-brand-one;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    background-color: $color-neutral-three;
    text-align: center;
    padding: 15px;
    cursor: pointer;

    &--focus {
      outline: 2px solid $blueFocus;
    }

    &--error {
      border: 1px solid $color-input-validation-error-border;
    }
  }

  & .c-input-wrapper {
    height: 100%;
  }
}

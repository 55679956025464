
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-input-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 18px;

  & + .c-input-wrapper__validation {
    margin-top: 0;
  }

  &--with-annex {

    .c-input-label__label {
      padding-right: 30px;
    }
  }

  &--inline-annex {
    justify-content: flex-start;

    .c-input-label__annex {
      position: relative;
    }

    .c-input-label__label {
      padding-right: 15px;
    }
  }

  &--secondary {

    .c-input-label__label {
      font-weight: $font-weight-semibold;
    }
  }

  &--no-margin {
    margin: 0;
  }

  &--bold {

    .c-input-label__label {
      font-weight: $font-weight-semibold;
    }
  }

  &__label {
    font-size: $font-size-base;
    line-height: 1.5;
    color: $color-text-form-default;
    word-break: break-word;
  }

  &__annex {
    align-self: center;
    position: absolute;
    right: 0;

    .c-helper__trigger {
      margin: 0;
    }
  }

}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$grey-gradient: #f0f0f0;

.c-shimmer {
  background: linear-gradient(to top, $color-neutral-three, $grey-gradient);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &:after {
    content: "";
    display: block;
    width: 60px;
    height: calc(142% + 60px);
    top: -43px;
    transform: rotate(45deg);
    transform-origin: 0 0;
    background: linear-gradient(
      -90deg,
      rgba($white, 0.1),
      $white,
      rgba($white, 0.1)
    );
    opacity: 0.7;
    position: absolute;
    z-index: 1;
    animation-name: light;
    animation-duration: 3s;
    animation-delay: 0.05s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;

    @include breakpoint(small) {
      animation: none;
      left: 100%;
    }
  }
}

@keyframes light {
  0% {
    left: 0;
  }
  100% {
    left: 200%;
  }
}

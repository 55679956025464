
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-button {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 100%;
  border: 3px solid;
  border-radius: 25px;
  text-align: center;
  text-transform: uppercase;
  font-family: $font-secondary;
  font-size: $font-size-base;
  line-height: $font-size-base;
  font-weight: $font-weight-buttons-regular;

  @include defaultTransition(none);

  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }

  &:focus {
    box-shadow: 0 0 0 1px $color-border-button-secondary-hover;
  }

  &:active {
    // Using text decoration on span cause text lies in it, and we don't need underline icon
    .c-button__text {
      text-decoration: underline;
      pointer-events: none;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &--loading {
    pointer-events: none;
  }

  &--small {
    min-height: 30px;
    border-width: 1px;
    @apply p-0 px-2.5;
  }

  &--medium {
    height: 40px;
    @apply p-0 px-2.5;
  }

  &--large {
    min-height: 50px;
  }

  &--thin {
    @apply border text-s;
  }

  &__icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "spotlighticon" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    pointer-events: none;
    font-size: 37px;
  }

  &__input-inset {
    font-size: 37px;
    padding: 7px 6px 6px 7px;
    border: none;
    border-radius: 0;
  }

  &__primary {
    background: $color-background-button-primary;
    border-color: $color-border-button-primary;
    color: $color-text-button-primary;

    &:hover {
      background: $color-background-button-primary-hover;
      border-color: $color-border-button-primary-hover;
    }

    &:focus {
      outline: 2px solid $color-border-button-primary-hover;
    }

    &:hover,
    &:focus {
      color: $color-text-button-primary-hover;
    }

    &.c-button__disabled {
      pointer-events: none;
      background: $color-background-button-primary-disabled;
      border-color: $color-border-button-primary-disabled;
      color: $color-text-button-primary-disabled;

      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  &__secondary {
    background: $color-background-button-secondary;
    border-color: $color-border-button-secondary;
    color: $color-text-button-secondary;

    &:hover,
    &:focus {
      background: $color-background-button-secondary-hover;
      border-color: $color-border-button-secondary-hover;
      color: $color-text-button-secondary-hover;
    }
  }

  &__tertiary {
    flex-direction: row-reverse;
    gap: 2px;
    padding: 10px;
    text-transform: none;
    border: 1px solid;
    font-family: $font-primary;
    font-size: $font-size-medium;
    font-weight: $font-weight-semibold;
    background: $color-background-button-tertiary;
    border-color: $color-border-button-tertiary;
    color: $color-text-button-tertiary;

    &:hover,
    &:focus {
      background: $color-background-button-tertiary-hover;
      color: $color-text-button-tertiary-hover;
    }

    &:focus {
      border-color: $color-brand-two;
    }

    .c-button__icon {
      font-size: $font-size-icon-button-tertiary;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-close-icon {
  &:focus {
    outline: 2px solid $blueFocus;
    border-radius: 3px;
  }
}

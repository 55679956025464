
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-main-nav-item {
  position: relative;
  &__selected {
    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 50px;
      border-left: 8px solid $color-brand-one;
      cursor: pointer;
    }
  }
  &__category {
    display: block;
    padding: 16px 20px 16px 65px;
    transition: all 0.2s ease-in-out;
    color: $color-neutral-one;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    font-size: $font-size-small;
  }
  &__icon {
    position: absolute;
    height: 25px;
    width: 25px;
    margin: 12.5px 0 0 20px;
    &.selectedIcon {
      filter: invert(38%) sepia(9%) saturate(7428%) hue-rotate(182deg)
        brightness(91%) contrast(87%);
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__child {
    padding-bottom: 15px;
  }
  &__container {
    &:hover {
      background-color: $color-background-main-nav-hover;
    }
  }
}

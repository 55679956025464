
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-page-section {
  @include globalOuterPadding;

  &--no-padding-sm {
    @include breakpoint(small) {
      padding: 0;
    }
  }
}

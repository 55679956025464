
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-icon-text-link {
  $c: c-icon-text-link;

  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  color: $color-neutral-one;
  outline: none;
  align-items: center;
  gap: 5px;
  border-radius: 25px;
  padding: 0 10px;
  margin: 0;
  min-height: 34px;

  &:hover,
  &:active,
  &.#{$c}--active {
    text-decoration: underline;
  }

  &__text {
    display: block;
    transition: all 0.2s ease-in-out;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    font-size: $font-size-medium;
  }

  &__icon {
    height: 25px;
    width: 25px;
  }

  &__icon-svg {
    height: 25px;
    width: 25px;
  }

  &__icon-font {
    font-size: 25px;
  }

  &--primary {
    color: $color-brand-two;
    font-weight: $font-weight-semibold;
    align-items: center;

    .#{$c}__icon-svg,
    .#{$c}__icon-svg path {
      fill: $color-brand-two;
    }

    &:focus {
      outline: 2px solid $blueFocus;
      border-radius: 4px;
    }
  }

  &--secondary {
    @include secondary-link;

    .#{$c}__icon-svg,
    .#{$c}__icon-svg path {
      fill: $color-brand-one;
    }

    &:focus {
      outline: 2px solid $blueFocus;
    }
  }

  &--tertiary {
    color: $color-brand-two;
    border-radius: 0;
    padding: 12px 10px;

    .#{$c}__text {
      transition: none;
    }

    .#{$c}__icon,
    .#{$c}__icon-svg,
    .#{$c}__icon-svg path {
      fill: $color-brand-two;
    }

    &:hover {
      text-decoration: none;
    }

    &:hover,
    &:focus {
      background: $color-brand-two;
    }

    &:focus,
    &:active {
      text-decoration: underline;
      border: 2px solid $blueFocus;
    }

    &:active {
      background: $color-background-dark;
    }

    &:hover,
    &:focus,
    &:active {
      color: $white;

      .#{$c}__icon-svg,
      .#{$c}__icon-svg path {
        fill: $white;
      }
    }
  }

  &--quaternary {
    color: $white;
    border: 1px solid $white;
    border-radius: 25px;

    .#{$c}__icon-svg,
    .#{$c}__icon-svg path {
      fill: $white;
    }

    .#{$c}__text {
      margin: 0;
      padding: 0;

      font-weight: $font-weight-semibold;
      transition: none;
    }

    &:hover {
      text-decoration: none;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $white;
    }

    &:hover {
      color: $color-brand-two;
      border-color: $color-brand-two;

      .#{$c}__icon-svg,
      .#{$c}__icon-svg path {
        fill: $color-brand-two;
      }
    }

    &:active,
    &:focus {
      color: $color-brand-two;
      border-color: $color-brand-two;
      text-decoration: underline;

      .#{$c}__icon-svg,
      .#{$c}__icon-svg path {
        fill: $color-brand-two;
      }
    }

    &:focus {
      outline: 2px solid $blueFocus;
    }
  }

  &--mainnav {
    border-radius: 0;
    min-height: 50px;
    gap: 10px;

    &:hover,
    &:active,
    &.#{$c}--active {
      text-decoration: none;

      .#{$c}__icon-svg,
      .#{$c}__icon-svg path {
        fill: $color-brand-one;
      }
    }

    .#{$c}__text {
      font-size: $font-size-small;
    }

    &:focus {
      outline: 2px solid $blueFocus;
    }
  }

  &--reversed {
    flex-direction: row-reverse;
    width: fit-content;
    max-width: 100%;
  }

  &--button-icon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $light-grey-2;
    border-radius: 50px;
    color: $color-brand-two;
    background-color: $white;

    .#{$c}__icon {
      position: relative;
      margin: 0;
      overflow: hidden;
      padding-right: 25px;
      padding-top: 1px;
    }

    .#{$c}__icon,
    .#{$c}__icon-svg,
    .#{$c}__icon-svg path {
      fill: $color-brand-two;
    }

    &:hover,
    &:active,
    &.#{$c}--active {
      background-color: $sky-blue-2;
    }

    &:hover,
    &:focus,
    &:active {
      color: $color-text-default;
    }

    &-secondary {
      background-color: $sky-blue-2;
      color: $color-text-default;

      &:active,
      &.#{$c}--active {
        box-shadow: 0 0 0 1px $color-brand-two;
      }
    }
  }

  &--button-icon-text,
  &--button-icon-text-secondary {
    .#{$c}__text {
      font-weight: $font-weight-semibold;
      width: auto;
    }

    &:hover {
      text-decoration: none;
    }

    &:hover,
    &:active,
    &:focus,
    &.#{$c}--active {
      background-color: $sky-blue-2;
    }

    &:focus {
      box-shadow: 0 0 0 2px $color-brand-two;
    }
  }
}

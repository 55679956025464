
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$toggle-button-width: $input-height;
$color-date-range: #b1f1e3;

@mixin year-decade-grid-override {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 14px;

  @include breakpoint(small) {
    grid-template-columns: 1fr 1fr;
  }

  .react-calendar__tile {
    max-width: unset !important;

    &:focus {
      background-color: $color-neutral-three;
      text-decoration: underline;
    }

    abbr {
      text-transform: capitalize;
    }
  }
}

@mixin year-decade-button-override {
  padding: 12px 0 15px 0;
  border: 1px solid $color-outline-inputs;
  font-weight: $font-weight-semibold;
}

.c-datepicker-universal {
  &--input-validation {
    margin-bottom: 40px;
  }

  //INPUT STYLES
  &__input {
    width: 100%;
    display: flex;
    align-items: flex-end;

    &__toggle {
      flex-basis: $toggle-button-width;
      height: $input-height;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $color-background-button-primary;
      color: $color-text-button-primary;
      outline: none;

      &--disabled {
        background: $light-grey-2;
        pointer-events: none;

        svg #dateicon {
          fill: $color-neutral-two;
        }
      }

      &:hover {
        background: $color-background-button-primary-hover;
        color: $color-text-button-primary-hover;
      }
    }

    &__field {
      flex-basis: calc(100% - 50px);

      .c-input-wrapper {
        margin-bottom: 0;
      }

      &--selected {
        input {
          padding-right: 40px;
        }
      }
    }
  }

  &__calendar-positioner {
    position: relative;
    width: 100%;
    z-index: $important-zindex;
  }

  &__calendar-arrow {
    position: absolute;
    right: 0;
    z-index: 2;
    display: inline-block;
    height: 0;
    width: 0;
    border-right: 25px solid transparent;
    border-bottom: 25px solid $color-neutral-three;
    border-left: 25px solid transparent;
    margin-bottom: -5px;
  }

  &__calendar-modes {
    max-width: 560px;
    width: 100%;
    min-width: 440px;
    padding: 0 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include breakpoint(small) {
      flex-direction: column;
      min-width: 0;
      padding: 0 15px 15px;
    }
    .c-radio__input:checked + .c-radio__content {
      font-weight: $font-weight-semibold;
    }
  }

  &__calendar-mode {
    @include breakpoint(small) {
      padding: 10px 0 0;
    }
  }

  &__error-message {
    margin: -5px 5px 15px;
    height: 20px;
  }
  //CALENDAR STYLES

  &__calendar-wrapper {
    top: 25px;
    position: absolute;
    background: $color-background-datepicker;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    padding: 15px;

    @include breakpoint(small) {
      padding: 10px;
      right: 0;
    }

    &--align-right {
      right: 0;
    }
  }

  .react-calendar {
    max-width: 390px;
    width: 390px;
    margin: 0 auto;
    border-color: map-get($grey, 90);
    @include breakpoint(small) {
      width: 280px;
      margin: 0 auto;
    }

    &__navigation {
      margin-bottom: 0;

      &__arrow {
        color: $color-brand-one;
        font-size: $font-size-icon-default;
        min-width: unset;
        &:enabled:hover,
        &:enabled:focus {
          background: $color-neutral-three;
        }
      }

      &__label {
        color: $color-neutral-one;
        font-family: $font-primary;
        font-size: $font-size-base;
        line-height: normal;
        font-weight: $font-weight-semibold;
        text-transform: capitalize;
        &:enabled:hover,
        &:enabled:focus {
          background: $color-neutral-three;
        }

        &[disabled] {
          background: white;
        }
      }
    }

    &__tile {
      font-family: $font-primary;
      font-size: $font-size-small;
      font-weight: $font-weight-semibold;
      line-height: normal;
      color: $color-neutral-one;
      padding: 11px 0;

      &:hover {
        background-color: $color-neutral-three;
      }
      &:focus {
        background-color: $mint-green;
      }

      &--now {
        border: solid 1px $light-grey-2;
      }

      &--active {
        background-color: $color-date-range;
      }

      &--rangeStart,
      &--rangeEnd,
      &--hasActive {
        background-color: $mint-green;
        &.react-calendar__month-view__days__day--neighboringMonth {
          opacity: 1;
        }
      }
    }

    &__month-view {
      &__weekdays__weekday {
        font-family: $font-primary;
        font-size: $font-size-small;
        line-height: normal;
        color: $color-neutral-one;
        text-transform: capitalize;
        padding: 10px 0 5px;
        font-weight: $font-weight-semibold;
        abbr {
          text-decoration: none;
        }
      }

      &__days__day--neighboringMonth {
        opacity: 0.35;
      }
    }

    &__year-view {
      padding: 10px;

      &__months {
        @include year-decade-grid-override;

        &__month {
          @include year-decade-button-override;
        }
      }
    }

    &__decade-view {
      padding: 10px;

      &__years {
        @include year-decade-grid-override;

        &__year {
          @include year-decade-button-override;
        }
      }
    }

    &--selectRange {
      .react-calendar__tile {
        &--hover,
        &:hover {
          background-color: $color-date-range;
        }

        &--rangeStart:hover,
        &--rangeEnd:hover {
          background-color: $mint-green;
        }
      }
    }
  }
}

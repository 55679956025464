
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.switch {
  @apply relative inline-block w-full h-[32px] flex items-center gap-2;

  .slider {
    @apply absolute cursor-pointer bg-color-neutral-three border-color-neutral-one border-[1px] inset-0 duration-[0.4s] w-[54px] h-[32px];

    &:before {
      @apply content-["\00d7"] absolute h-6 w-6 left-1 bottom-[3px] bg-white duration-[0.4s] text-white text-center text-[30px] leading-6;
    }

    &.round {
      @apply rounded-[34px];

      &:before {
        @apply rounded-[50%] bg-color-neutral-one;
      }
    }
  }

  input {
    @apply opacity-0 w-0 h-0;

    &:checked + .slider {
      @apply bg-color-brand-one border-color-brand-one;

      &:before {
        @apply content-[""];
      }
    }
  }

  input:checked + .slider:before {
    @apply translate-x-5;
  }

  input:checked + .round:before {
    @apply bg-white;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-section-table-row {
  margin: 0 0 5px;
  grid-template-columns: repeat($grid-columns, 1fr);

  &.g-inner-grid {
    @include breakpoint(small) {
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__column {
    margin: 0;
    font-size: $font-size-base;
    line-height: 1.5;

    &:first-child {
      font-weight: $font-weight-semibold;
      grid-column: 1 / span 3;

      @include breakpoint(small) {
        font-size: $font-size-medium;
      }
    }

    &:last-child {
      grid-column: 5 / span 9;

      @include breakpoint(small) {
        font-size: $font-size-medium;
      }
    }

    p,
    div {
      margin: 0 0 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-tags-overview-popup {
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 10px 10px 15px;
  }

  &__filter-tag {
    margin-left: 0 !important;
    margin-right: 5px !important;
    margin-bottom: 15px;
  }
}

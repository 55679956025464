
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.SelectFormik {
  &__container {
    position: relative;
  }

  &__scrollAnchor {
    position: absolute;
    top: -52px;
    pointer-events: none;
  }

  &__option {
    transition: background 60ms;

    &:hover {
      background: #fff;
    }
  }

  &__no-options-text {
    display: flex;
    justify-content: center;
    padding: 10px;
    padding-top: 5px;
    opacity: 0.75;
  }
}

// remove box shadow which is inherited from other places
.c-input-wrapper--invalid-error input:focus {
  box-shadow: none;
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$audio-player-bg: $shark-grey-5;

.c-audio-player {
  max-height: 100%;
  display: flex;
  flex-flow: column;
  background-color: $audio-player-bg;
}

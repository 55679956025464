
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$input-padding: 12px;

.c-input {
  input {
    padding: 0 $input-padding;
    height: $input-height;

    &::-ms-clear {
      display: none;
    }
  }

  .c-input-field {
    &--white {
      background-color: $white;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  &.g-with-search-icon input,
  .g-with-search-icon & input {
    padding-left: 35px;
  }

  &.g-input-with-cleaner input,
  .g-input-with-cleaner & input {
    padding-right: 50px;

    &:placeholder-shown {
      padding-right: $input-padding;
    }
  }

  &__container {
    position: relative;
    &--medium {
      .c-cleaner {
        height: $input-medium-height;
      }

      .c-input-search-icon {
        height: $input-medium-height;
      }
      input {
        height: $input-medium-height;
      }
    }

    &--with-button {
      display: flex;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-input-wrapper {
  textarea.text-area-formik {
    padding: 6px 12px;
    border: 1px solid $color-neutral-two;
    outline: none;
    height: 100px;
    border-bottom: 0;

    &:focus {
      outline: 2px solid $blueFocus;
    }

    &--resize-false {
      resize: none;
    }

    &--resize-true {
      resize: vertical;
    }

    &--invalid {
      border-color: $color-input-validation-error-text;
    }

    &--with-length {
      padding-bottom: 25px;
    }

    &--custom-rows {
      height: auto;
    }
  }

  &__text-length-below {
    display: block;
    position: static;
    width: 100%;
    border: 1px solid $color-neutral-two;
    border-top: 0;
    padding-left: 5px;
    padding-bottom: 1px;
    background: $color-neutral-three;
  }

  textarea.text-area-formik:focus + &__text-length {
    border-bottom: 1px solid $blueFocus;
  }
}

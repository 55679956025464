
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-break-view {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 6px;
  min-width: 20px;
  color: $color-neutral-one;
  user-select: none;
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-input-group {
  background-color: $color-background-form-default;
  color: $color-text-form-default;
  padding: 20px;

  &--secondary {
    @include backgroundSecondary;
  }

  &--tertiary {
    @include backgroundTertiary;
  }

  &--no-padding {
    padding: 0;
  }
}

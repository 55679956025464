
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.card-element {
  & > div {
    @apply h-[50px] border-[1px] border-solid border-color-neutral-one pl-[15px] bg-color-neutral-three;
  }

  &.invalid {
    & > div {
      @apply border-red-600;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.radio-group-formik {
  &__container {
    border: none;
  }

  &__fields {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

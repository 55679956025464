
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-dropdown-checkbox-value {
  &__placeholder {
    color: $color-neutral-one;
  }

  &__container {
    color: $color-neutral-one;
  }

  &__selection {
    font-weight: $font-weight-semibold;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-page-title {
  @include globalOuterPadding;
  margin: 10px 0;
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
@import "../../GlobalStyles/scrollbar";

.c-autocomplete {
  border: 1px solid $color-neutral-two;
  position: absolute;
  min-width: 100%;
  max-height: 283px;
  overflow-y: auto;
  transition: 0.3s;
  background-color: $color-background-item-default;
  z-index: 1;

  @include styled-scrollbar;

  &.c-autocomplete__hidden {
    max-height: 0;
    overflow-y: hidden;
    border: none;
    transition: 0.3s;
  }

  &__item {
    padding: 14px 19px;
    color: $color-neutral-one;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;

    &:hover,
    &:focus {
      background-color: $color-background-item-accent-default;
      outline: none;
    }
  }
}

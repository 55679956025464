
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.subheaders {
  @apply text-[18px] md:text-[22px] font-semibold leading-[27px] md:leading-[33px] mb-[18px];
  @apply flex flex-row justify-between w-full items-center;
}

.discount-code__input-LEGACY {
  width: 100%;
  .c-input-wrapper {
    margin: 0;
    .c-input-label__label {
      @apply text-[18px] leading-[27px] md:text-[22px] md:leading-[33px] font-semibold;
    }
  }

  input {
    @apply pr-[75px];
  }
}

.boldText {
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
}

.success-icon {
  circle {
    @apply text-teal-400;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-slide-down {
  height: 0;
  transition-property: none;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;

  &.c-slide-down__transitioning {
    overflow-y: hidden;
  }

  &.c-slide-down__closed {
    display: none;
  }
}

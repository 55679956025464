
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-section-divider {
  &--outer-padding {
    @include globalOuterPadding;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$arrow-width: 44px;

.c-pagination-control {
  display: flex;
  flex-direction: row;
  height: 34px;

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: $arrow-width;
    height: 44px;
    outline: none;

    &:hover,
    &:focus {
      background-color: $color-background-pagination-arrow-hover;
    }

    &:focus {
      outline: 2px solid $color-outline-tab;
    }

    &-icon {
      font-size: 25px;
      color: $color-brand-one;
    }
  }

  &__arrow-empty-space {
    width: $arrow-width;
  }
}

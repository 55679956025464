
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-responsive-image {
  
  img {
    display: block;
    width: 100%;
  }
}


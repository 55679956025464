
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-tab {
  position: relative;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  border-right: 1px solid $color-border-tab;
  transition: 0.3s;
  text-align: center;
  white-space: nowrap;
  padding: 0 15px;

  &__container {
    min-width: 40px;

    &-invisible {
      font-weight: $font-weight-semibold;
      height: 0;
      overflow: hidden;
    }
  }

  &.active {
    font-weight: $font-weight-semibold;

    &::before {
      opacity: 1;
    }
  }

  &::before {
    background-color: $color-outline-tab;
    height: 8px;
    width: 100%;
    left: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transition: background-color 0.3s;
    opacity: 0;
  }

  &:hover {
    background-color: $color-background-tab-active;
    transition: background-color 0.3s;
    color: $white;
  }

  &:focus {
    outline: none;
    background-color: $color-background-tab-active;
    transition: background-color 0.3s;
    color: $white;
    text-decoration: underline;
    border-radius: 3px;

    &:before {
      opacity: 0;
    }

    .c-tab__container {
      margin-top: -2px;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-file-uploader {
  &__dropzone-text {
    @apply flex items-center gap-1;
  }

  &__dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    border: dashed 1px $light-grey-2;
    color: $color-brand-one;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    background-color: $color-neutral-three;
    text-align: center;
    padding: 15px;
    cursor: pointer;

    &--error {
      border: 1px solid $color-input-validation-error-border;
    }
  }

  &__dropzone-loading {
    height: 70px;
    border: dashed 1px $light-grey-2;
  }

  &__files {
    ul {
      @include removeDefaultListStyling;
    }
  }

  &--error {
    border: 1px solid $color-input-validation-error-border;
  }

  &__dropzone + &__files ul {
    margin-top: 20px;
  }
}

.g-bg-secondary,
.c-input-group--secondary {
  .c-file-uploader {
    &__dropzone {
      background-color: $white;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
@import "../../GlobalStyles/scrollbar";

.c-select {
  &__control {
    font-size: $font-size-base;
    position: relative;
    color: $color-neutral-one;
    background-color: $color-background-item-default;
  }

  & label {
    font-size: $font-size-base;
    margin-bottom: 14px;
    display: block;
  }

  &__selected {
    height: 50px;
    box-shadow: 0 0 0 1px $color-neutral-two;
    padding-left: 11px;
    padding-right: 35px;
    line-height: 50px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:before {
      content: "";
      position: absolute;
      right: 20px;
      top: calc(50% + 2.5px);
      border: 6px solid transparent;
      border-top: 10px solid $color-neutral-one;
    }

    &:after {
      content: "";
      position: absolute;
      right: 20px;
      top: calc(50% - 18.5px);
      border: 6px solid transparent;
      border-bottom: 10px solid $color-neutral-one;
    }
  }

  &.top &__list {
    bottom: 50px;
  }

  &.bottom &__list {
    top: 50px;
  }

  &__list {
    background-color: $color-background-item-default;
    padding: 0;
    overflow-y: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    max-height: 0;
    transition: 0.3s;
    z-index: 1;

    &.opened {
      outline: 1px solid $color-neutral-two;
      max-height: 200px;
      overflow-y: auto;
      transition: 0.3s;
    }

    @include styled-scrollbar;
  }

  &__list-item {
    padding: 14px;
    margin: 0;
    cursor: pointer;
    list-style-type: none;

    &:hover,
    &:focus {
      background-color: $color-background-item-accent-default;
    }

    &.selected {
      background-color: $color-background-item-accent-default;
    }

    &.bold {
      font-weight: 600;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
@import '../../styles/variables';

.c-header-section {
  &__navigation-menu {
    // menu height minus Header height when absolute positioned
    max-height: $menu-height;

    @include breakpoint(large) {
      max-height: 100%;
    }
  }
}

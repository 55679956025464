
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-page-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 28px;
  cursor: pointer;
  margin-left: 3px;
  font-size: $font-size-medium;
  color: $color-text-pagination-break;
  border-bottom: $border-width-medium solid $color-border-page-view;
  outline: none;

  @include breakpoint(medium_up) {
    &:hover {
      color: $white;
      background-color: $color-background-page-view-hover-focus;
      border-bottom: $border-width-medium solid $color-border-selected-page-view;
    }
  }

  &:focus {
    background-color: none;
    border-bottom: $border-width-medium solid $color-border-selected-page-view;
    outline: 2px solid $blueFocus;
    text-decoration: underline;
  }

  &.selected {
    border-bottom: $border-width-medium solid $color-border-selected-page-view;
  }
}

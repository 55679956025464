
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$input-padding: 12px;

.select {
  position: absolute;
  width: 105px;
}

.phone-label {
  display: flex;
  justify-items: flex-start;
  padding-left: 8px;
  font-size: 16px;

  &__icon-section {
    display: flex;
    align-items: center;
    width: 80px;
  }

  &__country-name {
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  &__icon {
    height: 30px;
    min-height: 30px;
    width: 30px;
    min-width: 30px;
    margin-right: 7px;
  }
}

.c-input-phone {
  width: 100%;
  input {
    padding: 0 $input-padding;
    padding-left: 115px;
    height: $input-height;

    &::-ms-clear {
      display: none;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
@import "icons";
@import "flags";

// More futura-pt variants exist at https://drive.google.com/drive/folders/1Q_PpE7drs9gCrANvG9XUNET8XXmwm1DV; already hosted on staticassets
@font-face {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;

  src: url(#{$fonts-url} + "futura-pt/futura-pt_medium.woff2") format("woff2");
}

html,
body {
  font-family: $font-primary;
  font-size: $font-size-base;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-regular;
}

h1,
.text-xx-large {
  font-size: calculate-rem($font-size-xx-large);

  @include breakpoint(small) {
    font-size: calculate-rem($font-size-large);
  }
}

h2,
.text-x-large {
  font-size: calculate-rem($font-size-x-large);

  @include breakpoint(small) {
    font-size: calculate-rem(20px);
  }
}

h3,
.text-large {
  font-size: calculate-rem($font-size-large);

  @include breakpoint(small) {
    font-size: calculate-rem($font-size-base);
  }
}

h4 {
  font-size: calculate-rem($font-size-base);
  font-weight: $font-weight-semibold;
}

p,
.text-normal {
  font-size: calculate-rem($font-size-base);

  @include breakpoint(small) {
    font-size: calculate-rem($font-size-medium);
    line-height: 1.25;
  }
}

.hero-heading {
  font-size: calculate-rem(50px);
  font-family: $font-secondary;
}

*.text-center {
  text-align: center;
}

*.text-small {
  font-size: calculate-rem($font-size-medium);
  line-height: 1.25;
}

*.text-smaller {
  font-size: calculate-rem($font-size-small);
  line-height: 1.25;
}

*.text-tiny {
  font-size: calculate-rem($font-size-tiny);
  line-height: 1.25;
}

*.text-center-sm {
  @include breakpoint(small) {
    text-align: center;
  }
}

*.text-center-md {
  @include breakpoint(medium) {
    text-align: center;
  }
}

*.text-center-lg {
  @include breakpoint(large) {
    text-align: center;
  }
}

*.text-right {
  text-align: right;
}

*.text-right-sm {
  @include breakpoint(small) {
    text-align: right;
  }
}

*.text-right-md {
  @include breakpoint(medium) {
    text-align: right;
  }
}

*.text-right-lg {
  @include breakpoint(large) {
    text-align: right;
  }
}

@mixin calculate-start-for-grid-lg($grid-columns) {
  @for $i from 1 through $grid-columns {
    @for $j from 1 through $grid-columns {
      // .g-col-sm-1, .g-col-sm-2, .... .g-col-sm-12
      .g-start-lg-#{$i}.g-col-lg-#{$j} {
        @include breakpoint(large) {
          grid-column-start: ($i);
          grid-column-end: ($i + $j - 2 - $grid-columns);
        }
      }
    }
  }
}

@mixin calculate-start-for-grid-md($grid-columns) {
  @for $i from 1 through $grid-columns {
    @for $j from 1 through $grid-columns {
      // .g-col-sm-1, .g-col-sm-2, .... .g-col-sm-12
      .g-start-md-#{$i}.g-col-md-#{$j} {
        @include breakpoint(medium) {
          grid-column-start: ($i) !important;
          grid-column-end: ($i + $j - 2 - $grid-columns) !important;
        }
      }
    }
  }
}

// Styles for classes that define a grid area
.g-content-area,
.g-inner-grid {
  @include grid-content-area();
}

@for $i from 1 through $grid-columns {
  .g-inner-grid-#{$i} {
    @include grid-content-area();
  }
}

// Spacing and centering for the main g-content-area
.g-content-area {
  margin: 0 auto;
  max-width: $global-width;
  grid-template-columns: repeat($grid-columns, 1fr);
  @include globalOuterPadding();
  @include calculate-start-for-grid-lg($grid-columns);
  @include calculate-start-for-grid-md($grid-columns);
}

// Grid layout for inner grids with varying numbers of columns
@for $i from 1 through $grid-columns {
  .g-inner-grid-#{$i},
  .g-inner-grid.g-col-lg-#{$i} {
    grid-template-columns: repeat($i, 1fr);
    @include calculate-start-for-grid-lg($i);
  }

  .g-inner-grid.g-col-md-#{$i} {
    @include breakpoint(medium) {
      grid-template-columns: repeat($i, 1fr);
      @include calculate-start-for-grid-md($i);
    }
  }

  .g-inner-grid.g-col-sm-#{$i} {
    @include breakpoint(small) {
      grid-template-columns: repeat($i, 1fr);
    }
  }
}

.g-bottom-spacing {
  margin-bottom: 30px;
}

@for $i from 1 through $grid-columns {
  // .g-col-lg-1, .g-col-lg-2, .... .g-col-lg-12
  .g-col-lg-#{$i} {
    grid-column: span $i;
    @include breakpoint(small) {
      grid-column: span 12;
    }
  }
}

@for $i from 1 through $grid-columns {
  // .g-col-md-1, .g-col-md-2, .... .g-col-md-12
  .g-col-md-#{$i} {
    @include breakpoint(medium) {
      grid-column: span $i !important;
    }
    @include breakpoint(small) {
      grid-column: span 12;
    }
  }
}

@for $i from 1 through $grid-columns {
  // .g-col-sm-1, .g-col-sm-2, .... .g-col-sm-12
  .g-col-sm-#{$i} {
    @include breakpoint(small) {
      grid-column: span $i !important;
    }
  }
}

@for $i from 1 through $grid-columns {
  // .g-row-start-sm-1, .g-row-start-sm-2, .... .g-row-start-sm-12
  @include breakpoint(small) {
    .g-row-start-sm-#{$i} {
      grid-row-start: $i;
    }
  }
}

$gridGaps: lg large $columnGridGapOnLarge, md medium $columnGridGapOnMedium,
  sm small $columnGridGapOnSmall;

@each $gridGap in $gridGaps {
  $className: nth($gridGap, 1);
  $breakpoint: nth($gridGap, 2);
  $value: nth($gridGap, 3);

  .g-row-gap-#{$className} {
    @include breakpoint($breakpoint) {
      grid-row-gap: $value !important;
    }
  }

  .g-column-gap-#{$className} {
    @include breakpoint($breakpoint) {
      grid-column-gap: $value !important;
    }
  }
}

.g-hidden-sm {
  @include breakpoint(small) {
    display: none !important;
  }
}

.g-hidden-md {
  @include breakpoint(medium) {
    display: none !important;
  }
}

.g-hidden-lg {
  @include breakpoint(large) {
    display: none !important;
  }
}

.g-hidden-empty:empty {
  display: none;
}

.g-no-scroll {
  // remove touch scroll to fix z-index for touch devices (iOS)
  -webkit-overflow-scrolling: initial;
  overflow: hidden !important;
}

.g-no-scroll-small {
  @include breakpoint(small) {
    -webkit-overflow-scrolling: initial;
    overflow: hidden !important;
  }
}

.g-no-scroll-medium {
  @include breakpoint(medium) {
    -webkit-overflow-scrolling: initial;
    overflow: hidden !important;
  }
}

.g-no-scroll-large {
  @include breakpoint(large) {
    overflow: hidden !important;
  }
}

a.g-tertiary-link {
  text-decoration: none;
  cursor: pointer;
  font-size: $font-size-base;
  font-weight: $font-weight-semibold;
  color: $color-text-link;

  &--white {
    color: $white;
  }

  &:hover {
    text-decoration: underline;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid $blueFocus;
    border-radius: 1px;
  }
}

a.g-alternative-link {
  align-self: center;
  text-decoration: none;
  cursor: pointer;
  font-size: $font-size-tiny;
  font-weight: $font-weight-semibold;
  @include secondary-link;

  &--light {
    color: $white;
  }

  &--md {
    font-size: $font-size-small;
  }

  &--lg {
    font-size: $font-size-medium;
    line-height: 20px;
  }

  &--xl {
    font-size: $font-size-large;
    line-height: 24px;
  }

  &--block-start {
    align-self: flex-start;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid $blueFocus;
    border-radius: 1px;
  }
}

a.g-alternative-link-dark {
  text-decoration: none;
  cursor: pointer;
  font-size: $font-size-tiny;
  font-weight: $font-weight-semibold;
  color: $color-text-alternative-link-dark;

  &--md {
    font-size: $font-size-small;
  }

  &--lg {
    font-size: $font-size-medium;
    line-height: 20px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.g-bg-default {
  @include backgroundDefault;
}

.g-bg-secondary {
  @include backgroundSecondary;
}

.g-bg-tertiary {
  @include backgroundTertiary;

  a:not([class]) {
    @include breakpoint(small) {
      color: $color-text-link;
    }
  }
}

.g-popup-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;

  &__buttons-set {
    padding: 15px;
    @include breakpoint(small) {
      display: flex;
      flex-direction: column;

      > div + div {
        margin-top: 10px;
      }

      &--reverse-small {
        flex-direction: column-reverse;

        > div + div {
          margin-bottom: 10px;
          margin-top: 0;
        }
      }
    }
  }

  &__scrollable {
    flex-grow: 1;
    overflow: auto;
  }

  &__header {
    @extend h3;
    min-height: 54px;
    padding: 10px 60px 10px 15px;
    border-bottom: solid 1px $medium-grey-3;
    display: flex;

    @include breakpoint(small) {
      font-size: calculate-rem(22px);
    }
  }
}

.g-split-line {
  margin-top: 0;
  margin-bottom: 0;
  border: solid 1px $medium-grey-3;
  border-top: none;
  width: 100%;
}

.g-dropdown-list {
  max-width: 100vw;
  border-radius: $dropdown-border-radius;
  overflow: hidden;
  position: absolute;
  background-color: $white;
  z-index: $dropdown-list-zindex;
  box-shadow: $dropdown-shadow;

  & > * {
    display: block;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 44px;
    border-bottom: 1px solid $light-grey-2;

    &:last-child {
      border-bottom: none;
    }
  }
}

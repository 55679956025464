
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$c: c-range-selection-dropdown;

.c-range-selection-dropdown {
  @include breakpoint(small) {
    height: 100%;
  }

  .c-input-wrapper {
    @include breakpoint(small) {
      height: 100%;
    }
  }

  &__form {
    height: 121px;

    @include breakpoint(small) {
      height: 227px;
    }

    .c-input-wrapper {
      margin: 0;
    }

    .c-input-label {
      margin-bottom: 14px;
    }
  }

  &__form-container {
    padding: 15px;
    display: flex;
    width: 100%;
    position: absolute;

    @include breakpoint(small) {
      flex-direction: column;
    }
  }

  &--column {
    .#{$c}__form {
      height: 227px;
    }

    .#{$c}__form-container {
      flex-direction: column;
    }

    .#{$c}__form-right-selector {
      padding: 15px 0 0;
    }

    .#{$c}__form-left-selector {
      padding-right: unset;
    }
  }

  &__form-left-selector {
    @include breakpoint(medium_up) {
      padding-right: 5px;
    }
  }

  &__form-right-selector {
    @include breakpoint(medium_up) {
      padding-left: 5px;
    }

    @include breakpoint(small) {
      padding-top: 15px;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$thumbnail-video-item-width: 142;

:export {
  thumbnailVideoItemWidth: $thumbnail-video-item-width;
}

.c-thumbnail-video {
  margin: 4px 1px 8px;
  display: inline-block;
  width: $thumbnail-video-item-width + px;
  font-family: $font-body;
  color: $white;
  cursor: pointer;
  padding: 4px;

  &:hover {
    background-color: $color-neutral-one;
  }

  &:focus {
    .c-thumbnail-video__file-name {
      text-decoration: underline;
    }
  }

  &:active {
    .c-thumbnail-video__file-name {
      text-decoration: underline;
    }
  }

  &--playing-video {
    background-color: $color-brand-two;

    &:hover {
      background-color: $color-brand-two;
    }
  }

  &__play-icon {
    background-size: cover;
    background-color: $color-neutral-two;
    width: 134px;
    height: 70px;
    position: relative;

    &:before {
      font-size: 28px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      opacity: 0.8;
    }
  }

  &__file-name {
    width: 100%;
    font-weight: $font-weight-semibold;
    margin: 6px 0 0 0;
    font-size: $font-size-small;
    line-height: $font-size-base;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__video-duration {
    width: 100%;
    font-weight: $font-weight-regular;
    font-size: $font-size-small;
    line-height: $font-size-base;
    margin: 0;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-section-row {
  @include globalOuterPadding;

  &--spacing-primary {
    padding-bottom: 10px;
  }

  &--spacing-secondary {
    padding-bottom: 20px;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.checkout-single-page {
  @apply flex flex-col;

  &__description {
    @apply p-2 pt-4;
    h1 {
      @apply text-xl md:text-2xl font-normal leading-10;
    }
    p {
      @apply md:text-lg max-w-3xl whitespace-normal my-3.5;
      font-size: 1rem;

      img {
        display: inline-block;
      }

      h2 {
        font-size: 22px;
      }

      ul,
      ol {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;

        li {
          line-height: 1.5;
        }
      }

      & ul {
        list-style-type: disc;
      }

      ol {
        list-style-type: decimal;
      }
    }
  }

  &__user-type {
    @apply text-[1.375rem] bg-color-brand-two text-white md:text-[1.7rem] font-normal leading-8 md:leading-10 py-4 px-2.5;
  }

  &__subscription {
    @apply flex md:flex-row justify-around content-center gap-5 flex-col flex-wrap lg:justify-between;
  }

  &__footer {
    @apply flex justify-end gap-5 w-full h-[50px] py-[5px] px-[10px] bg-color-neutral-three;
    @apply rounded-t-[5px] shadow-[0_-8px_10px_rgba(0,0,0,0.2)];
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.media-filter {
  input {
    &:checked ~ .c-checkbox__label, &:checked ~ .c-checkbox__label-postfix {
      font-weight: bold;
    }
  }

  .c-checkbox__label-postfix {
    margin-left: 5px;
    font-size: 14px;
    margin-top: 3px;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  @include breakpoint(small) {
    position: fixed;
    width: 100%;
  }
}

.root {
  height: 100%;
}

.a-layout {
  height: 100%;

  &__body {
    height: $menu-height;
    display: flex;
  }

  &__menu-container {
    max-height: 100%;
    z-index: 105;
  }

  &__content-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__footer-container {
    position: relative;
  }
}

.g-no-scroll .a-layout__content-container {
  -webkit-overflow-scrolling: unset;
}

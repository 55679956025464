
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.section {
  @apply pt-3.5 px-2.5 md:pt-8 pb-8 last:pb-0 border-solid border-b last:border-b-0 border-grey-light-2;

  &__header {
    @apply text-xl md:text-[26px] font-bold leading-[29px] md:leading-[39px] mb-5 md:mb-7;

    &_close {
      @apply opacity-40;
    }
  }
}

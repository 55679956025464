
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-performer-search {
  font-family: $font-primary;
  font-weight: $font-weight-regular;
  font-size: $font-size-medium;

  &__toggle-button {
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: $color-background-header-nav-item;
    border-left: 1px solid $color-border-side-header-nav-item;
    outline: none;

    @include breakpoint(large) {
      display: none;
    }

    @include breakpoint(small) {
      border-right: 1px solid $color-border-side-header-nav-item;
    }

    &:hover,
    &:focus {
      background-color: $color-background-header-nav-item-highlight;
    }
  }

  &__toggle-button-icon {
    font-size: 30px;
    color: $white;
    padding: 10px 10px 10px 9px;
  }

  &__form-container {
    position: relative;

    @include breakpoint(medium_down) {
      position: absolute;
      right: 0;
      z-index: 1;
    }

    @include breakpoint(small) {
      width: 100%;
      z-index: 10;
    }
  }

  &__form {
    padding: 6px 0 6px 10px;
    background-color: $color-neutral-one;
    height: 50px;
    display: flex;
    width: 270px;

    @include breakpoint(medium_down) {
      border-radius: 0 0 4px 4px;
    }

    @include breakpoint(medium) {
      width: 320px;
    }

    @include breakpoint(small) {
      width: 100%;
    }

    &--hidden {
      @include breakpoint(medium_down) {
        display: none;
      }
    }
  }

  &__form-input-container {
    position: relative;
    width: 100%;

    .c-cleaner {
      height: 38px;
    }

    @include breakpoint(medium_down) {
      width: 100%;
    }

    @include breakpoint(small) {
      position: unset;
    }
  }

  &__form-input {
    background-color: $shark-grey-5;
    color: $white;
    height: 38px;
    outline: none;
    line-height: 1.5;
    border-radius: 4px;
    border: unset;
    font-size: $font-size-medium;
    text-indent: 10px;
    padding-right: 43px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;

    &::placeholder {
      color: $white;
    }

    &:placeholder-shown {
      padding-right: 0;
    }

    @include breakpoint(medium_down) {
      width: 100%;
    }

    &--active,
    &:focus {
      color: $color-neutral-one;
      background-color: $white;
    }
  }

  &__form-icon {
    cursor: pointer;
    font-size: 30px;
    color: $white;
    padding: 4px 10px 4px 5px;
    outline: none;

    &:hover,
    &:focus {
      color: $color-brand-one;
    }
  }

  &__form-autocomplete {
    margin-top: -2px;

    > * {
      max-height: calc(100vh - 50px);
    }

    @include breakpoint(small) {
      margin: 4px 0 0 -10px;

      > * {
        max-height: calc(100vh - 100px);
      }
    }
  }

  & small {
    font-size: $font-size-tiny;
    white-space: nowrap;
  }

  &__not-found {
    padding: 14px 19px;
    color: $color-neutral-one;
    font-size: $font-size-base;
    line-height: 1.5;
  }

  &__show-more {
    line-height: 1.5;
    padding: 14px 19px;
  }

  &__show-more-link {
    outline: none;

    &:focus {
      text-decoration: underline;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$actionButton: c-action-button;
$defaultButtonSize: 70px;
$defaultButtonSizeSm: 63px;
$smallButtonSize: 36px;

.#{$actionButton} {
  font-size: 40px;
  width: $defaultButtonSize;
  height: $defaultButtonSize;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &--small {
    font-size: 37px;
    width: $smallButtonSize;
    height: $smallButtonSize;
  }

  &--xs {
    font-size: 30px;
    width: 30px;
    height: 30px;
  }

  &--toolbar {
    color: $color-brand-one;

    &:hover {
      background-color: $sky-blue-2;
    }
  }

  &.disabled {
    pointer-events: none;

    a {
      color: $color-neutral-two;
    }
  }

  &-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:focus {
      outline: none;

      .c-action-button-label {
        text-decoration: underline;
      }
    }
  }

  @include breakpoint(small) {
    margin-right: 0;

    &:not(&--small) {
      width: $defaultButtonSizeSm;
      height: $defaultButtonSizeSm;
    }
  }

  &-label {
    margin-top: 2px;
    font-size: $font-size-tiny;
    font-weight: $font-weight-semibold;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-stub {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: darken($light-grey-2, 2.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  &__container {
    width: 100%;
  }

  &__text {
    color: $color-neutral-one;
    text-align: center;
    font-weight: $font-weight-semibold;
    position: absolute;
    bottom: 15%;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
@mixin interaction-colorize($color) {
  &:hover,
  &:focus {
    color: $color;
  }
}

$font-size-header-large: 22px;
$c: c-expandable-row;

.c-breakdown-expandable-container-wrapper {
  border-top: 10px solid $burnt-orange;
  display: flex;
  justify-content: flex-end;
  width: 90%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-left: -15px;

  &__margin {
    margin-bottom: 20px;
  }
}

.c-breakdown-expandable-ui-flag {
  color: #fff;
  background-color: $burnt-orange;
  padding: 0 10px 5px 10px;
  margin-top: -5px;
  width: fit-content;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  gap: 7px;

  & svg {
    & path {
      fill: $burnt-orange;
    }
  }

  &__title {
    font-size: 16px;
  }
}

.c-expandable-row {
  background-color: $color-neutral-three;
  margin-bottom: 5px;

  &__header {
    padding: 15px;
    background-color: $sky-blue-2;
    transition: 0.5s ease-in;
    cursor: pointer;

    &--ui-flag {
      flex-direction: column;
      padding: 0 10px 13px 15px;
    }

    @include breakpoint(small) {
      flex-wrap: wrap;
    }

    &--normal {
      .#{$c}__header-content {
        font-size: $font-size-base;
      }
    }

    &--large {
      padding: 12px 10px 11px 15px;

      .#{$c}__header-content {
        font-size: $font-size-header-large;
      }
    }

    &--regular {
      .#{$c}__header-content {
        font-weight: $font-weight-regular;
      }
    }

    &--semibold {
      .#{$c}__header-content {
        font-weight: $font-weight-semibold;
      }
    }
  }

  &.header-uppercase &__header-content {
    text-transform: uppercase;
  }

  &__header-content {
    flex-grow: 1;
    color: $color-neutral-one;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.5s;

    @include breakpoint(small) {
      flex-basis: 82%;
    }
  }

  &__header-content-wrapper {
    display: flex;
    margin-top: 7px;
    align-items: flex-end;

    @include breakpoint(medium_up) {
      margin-top: -13px;
    }

    &--normal {
      display: flex;
      justify-content: space-between;

      @include breakpoint(small) {
        display: grid;
        gap: 6px;
        grid-template-areas:
          "controls controls"
          "title expand";
      }
    }
  }

  &__controls-wrapper {
    display: flex;

    @include breakpoint(small) {
      display: contents;
    }
  }

  &__header-controls {
    display: flex;
    margin: 0 16px;

    @include breakpoint(small) {
      margin: 0;
      grid-area: controls;
    }
  }

  &__header-control {
    display: flex;
    align-items: center;
    color: $color-neutral-one;
    cursor: pointer;
    transition: 0.5s ease;

    @include interaction-colorize($color-brand-one);

    &.disabled {
      color: $color-neutral-two;
      cursor: default;
    }

    &.no-interaction {
      cursor: default;
      pointer-events: none;
    }

    &:last-child {
      .#{$c}__control-text {
        margin-right: 10px;
      }
    }
  }

  &.expanded &__header-control {
    color: $color-neutral-one;

    @include interaction-colorize($color-brand-one);

    &.disabled {
      color: $color-neutral-two;
    }
  }

  &__control-text {
    margin-left: 10px;
    margin-right: 25px;
    font-size: $font-size-medium;
    font-weight: $font-weight-semibold;
  }

  &__control-icon {
    width: $font-size-icon-default;
    height: $font-size-icon-default;
    margin: -4px 2px;
    outline: 0;
    flex-shrink: 0;
    font-size: $font-size-icon-default;
  }

  &__header-expander {
    font-size: 40px;
    height: 40px;
    margin: -8px 0;
    color: $color-neutral-one;
    cursor: pointer;
    outline: 0;
    transition: 0.5s ease;

    @include interaction-colorize($color-brand-one);

    &:active {
      color: $color-brand-one;
    }

    &:focus {
      outline: 2px solid $blueFocus;
      border-radius: 4px;
    }
  }

  &.expanded &__header-expander {
    transform: scaleY(-1);
  }

  &__container {
    margin-bottom: -1px;
    border: 1px solid $color-neutral-three;
    border-top: 0;
    transition: border-color 0.5s;
  }

  &__slide-down {
    transition-duration: 0.5s;

    &::before,
    &::after {
      content: "";
      padding: 1px 0;
      display: block;
    }

    &::before {
      margin-bottom: -2px;
    }

    &::after {
      margin-top: -2px;
    }
  }

  &__content {
    display: none;

    &--expanded {
      display: block;
    }

    &::before,
    &::after {
      content: "";
      padding: 1px 0;
      display: block;
    }

    &::before {
      margin-bottom: -2px;
    }

    &::after {
      margin-top: -2px;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-page {
  &--full-height {
    flex: 1;
  }

  &--with-bottom-padding {
    padding-bottom: 50px;
  }

  &--background-primary {
    background-color: $color-background-form-default;
  }

  &--background-secondary {
    background-color: $color-background-form-secondary;
  }
}

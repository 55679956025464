
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$checkboxGroup: "c-checkbox-group";

$columnSizes: (
  "sm": small,
  "md": medium,
  "lg": large,
);

$columnGapSizes: (
  "sm": 0,
  "md": calculate-rem($columnGridGapOnMedium),
  "lg": calculate-rem($columnGridGapOnLarge),
);

.#{$checkboxGroup} {
  &--columns-horizontal {
    @each $name, $size in $columnSizes {
      @for $i from 2 through 5 {
        &.#{$checkboxGroup}--columns-#{$name}-#{$i} {
          .#{$checkboxGroup}__container {
            @include grid-content-area;

            @include breakpoint($size) {
              grid-template-columns: repeat($i, 1fr);
            }
          }
        }
      }
    }
  }

  &--columns-vertical {
    .#{$checkboxGroup}__container {
      display: grid;

      &-item {
        display: inline-block;
        width: 100%;
      }
    }

    @each $name, $size in $columnSizes {
      @for $i from 2 through 5 {
        &.#{$checkboxGroup}--columns-#{$name}-#{$i} {
          .#{$checkboxGroup}__container {
            @include breakpoint($size) {
              grid-template-columns: repeat($i, 1fr);
              column-gap: map-get($columnGapSizes, $name);
            }
          }
        }
      }
    }
  }

  &--disabled {
    .#{$checkboxGroup}__container {
      @include input-style-disabled-override;
    }
  }

  &__container {
    margin-left: 5px;
    margin-bottom: $input-margin-bottom;

    &--no-label {
      margin-left: -10px;
    }

    &--collapsible {
      margin-left: 30px;
    }

    &--no-bottom-margin {
      margin-bottom: 0;
    }
  }

  .c-input-wrapper {
    margin-bottom: 15px;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$marketing-text-width: (8/12) * 100%;

.c-my-account-page {
  // We want to reduce the font-size of the my account page. We can't just set font-size:80% here, because the font
  // size styles are done with rem units. Therefore, we have to override everything
  h2 {
    font-size: calculate-rem(26px * 0.8);
  }
  a.popup-link {
    font-size: 1rem;
  }
  a.c-button__with-icon {
    font-size: 37px;
  }

  h2 {
    margin: 0;
  }

  &__hero-area {
    color: $white;
    position: relative;
    background: $color-brand-two;

    min-height: 120px;
    @include breakpoint(small) {
      min-height: 100px;
    }
  }

  h1 {
    font-size: 30px;
    line-height: 46px;
    @include breakpoint(medium) {
      font-size: 1.8rem;
    }
    @include breakpoint(small) {
      padding-right: 0;
      font-size: 1.5rem;
    }
  }

  &__hero-area-content {
    position: relative;
  }

  &__notification-area {
    background: $pale-red;

    // however, the notification banner font sizes should be normal again
    a.c-button {
      font-size: $font-size-AA-large;
    }
    p {
      font-size: calculate-rem(18px);
    }

    .g-content-area {
      padding-top: 10px;
    }
    &__container {
      display: flex;
    }
    &__container,
    &__button {
      margin-bottom: 10px;
    }

    &__button {
      align-self: end;
    }

    &__message {
      flex: 1;
    }

    p {
      margin: 0;
    }

    &__message_description {
      display: block;
      margin-top: 10px;
    }
  }

  &__search-container {
    background: $color-brand-two;
    padding: 20px 15px;
    margin-bottom: 20px;
  }

  &__quick-links {
    background: $white;

    h2 {
      background: $color-brand-two;
      padding: 5px 10px;
      color: $white;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 10px;
      padding-bottom: 10px;
    }
  }

  &__service-message {
    background: $white;
    padding: 10px;
  }

  $marketingBannerHorizontalPadding: 15px;
  &__marketing-message {
    color: $color-text-default;
    padding: 20px $marketingBannerHorizontalPadding;
    padding-bottom: 65px;
    min-height: 220px;
    position: relative;

    @include breakpoint(small) {
      min-height: 247px;
    }
    &::before {
      top: 0;
      bottom: 0;
      left: 0;
      width: $marketing-text-width;
      position: absolute;
      content: "";
      background: rgba($white, 0.9);
    }
    a:not([class]) {
      color: $color-text-inverse;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__marketing-message-inner {
    position: relative;
    @include breakpoint(small) {
      width: $marketing-text-width - 5; // -5px to account for the padding
    }
  }

  &__marketing-cta {
    position: absolute;
    bottom: 15px;
    left: $marketingBannerHorizontalPadding;
  }

  &__chevron-button {
    width: 30px;
    height: 30px;
    color: $white;
    background: $color-brand-one;
    display: inline-block;
    border-radius: 100%;
    text-align: center;
    line-height: 30px !important;
    vertical-align: middle;
    margin-left: 15px;
  }

  &__main-area {
    background: $color-neutral-three;
    padding-top: 25px;
    padding-bottom: 50px;
  }

  &__video-container {
    display: flex;
    height: 100%;
    align-items: center;
  }
  &__video-container-inner {
    width: 100%;
    padding-top: 56.25%; // force aspect ratio to 16:9, so that the page does not jump
    position: relative;

    > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .c-video {
    display: flex; // fix the video height being weird, and also center the video

    .c-video__video {
      height: auto;
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-spotlight-footer {
  background-color: $color-background-footer;
  font-family: $font-primary;

  .c-links-list {
    color: $color-text-footer;
  }
}

.c-spotlight-footer__inner {
  margin: 0 auto;
  padding: 25px 0 50px;
}

.c-spotlight-footer__navigation {
  grid-row-gap: 30px;
}

.c-spotlight-footer__social {
  margin-top: 5px;
  direction: rtl;
}

.c-spotlight-footer__feedback {
  width: 100%;
  text-align: center;
  span {
    display: inline-block;
    width: 150px;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-radiolist {
  &--no-bottom-padding {
    .c-input-wrapper {
      margin-bottom: 0;
    }

    .c-radiolist__container {
      padding-bottom: 0;
    }
  }

  &--width-limited {
    & > .c-input-wrapper {
      & > .c-input-label,
      & > .c-radiolist__container > .c-radio > .c-radio__container {
        max-width: 50%;

        @include breakpoint(small) {
          max-width: 100%;
        }
      }
    }
  }

  &__vertical {
    & .c-radiolist__container {
      margin-top: 20px;
      padding-left: 15px;
    }

    .c-radio + .c-radio {
      margin-top: 15px;
    }
  }

  &:not(.c-radiolist__vertical) {
    .c-radio {
      display: inline-block;
      margin-right: 30px;
    }
  }

  &__container {
    padding-bottom: 10px;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$btnSplit: c-button-split;
$btn-split-trigger-height: 40px;

.#{$btnSplit} {
  @include user-select-none;
  overflow: visible;
  white-space: nowrap;
  display: flex;
  position: relative;

  &--up {
    flex-flow: column-reverse;
  }

  &--down {
    flex-flow: column;
  }

  &--loading {
    pointer-events: none;
  }

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    text-transform: uppercase;
    font-family: $font-secondary;
    font-size: $font-size-base;
    line-height: $font-size-base;
    font-weight: $font-weight-buttons-regular;
    height: $btn-split-trigger-height;
    width: 100%;
    background: $color-background-button-primary;
    color: $color-text-button-primary;
    cursor: pointer;
    border-radius: 25px;

    &:hover,
    &:focus {
      background: $color-background-button-primary-hover;
      color: $color-text-button-primary-hover;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $blueFocus;
    }

    &:after {
      content: "";
      width: 0;
      @include arrow("bottom", $white, 0px, 8px, 6px, 0px, 6px);
    }

    .#{$btnSplit}--disabled & {
      pointer-events: none;
      background: $color-background-button-primary-disabled;
      color: $color-text-button-primary-disabled;

      &:after {
        @include arrow(
          "bottom",
          $color-text-button-primary-disabled,
          0px,
          8px,
          6px,
          0px,
          6px
        );
      }
    }
  }

  &__label {
    margin-right: 10px;
    width: 100%;
    text-align: center;
  }

  &__actions-list-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    pointer-events: none;

    .#{$btnSplit}--up & {
      bottom: $btn-split-trigger-height;
    }

    .#{$btnSplit}--down & {
      top: $btn-split-trigger-height;
    }
  }

  &__actions-list {
    list-style: none;
    border-radius: $dropdown-border-radius;
    box-shadow: $dropdown-shadow;
    background: $white;
    pointer-events: auto;

    @include breakpoint(small) {
      max-width: 100%;
    }
  }

  &__action-container:not(:last-child)
    .#{$btnSplit}__action:not(:hover):not(:focus):not(:active) {
    border-bottom: 1px solid $color-border-button-split-action;
  }

  &__action-container:last-child
    .#{$btnSplit}__action:not(:hover):not(:focus):not(:active) {
    border-bottom: 1px solid transparent;
  }

  &__action-container {
    &:hover,
    &:focus {
      background: $color-background-button-primary;
    }

    &:active {
      background: $color-background-button-primary-hover;
    }

    &:first-child {
      &:hover,
      &:focus {
        border-radius: $dropdown-border-radius $dropdown-border-radius 0 0;
      }
    }

    &:last-child {
      &:hover,
      &:focus {
        border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
      }
    }
  }

  &__action {
    @include defaultTransition(none);

    display: block;
    height: 44px;
    padding: 10px 20px;
    cursor: pointer;
    font-family: $font-primary;
    font-weight: $font-weight-semibold;
    font-size: $font-size-AA-large;
    background: $color-background-button-secondary;
    color: $color-text-button-secondary;
    min-width: 145px;

    @include breakpoint(small) {
      padding: 12px 10px;
      @include ellipsis();
    }

    &:hover,
    &:focus,
    &:active {
      color: $color-text-button-primary;
    }

    &:hover,
    &:focus {
      background-color: $color-background-button-primary;
      outline: none;
    }

    &:focus {
      background-color: $color-background-button-primary;
      box-shadow: 0 0 0 2px $color-brand-two;
    }

    &:active {
      background-color: $color-background-button-primary-hover;
      text-decoration: underline;
    }
  }

  &__spinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.show-more-less-button {
  @apply fill-color-brand-two;
  & > path {
    @apply fill-color-brand-two;
  }
}

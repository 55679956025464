
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-filter-control {
  .c-dropdown {
    &__options-container, &__control, .c-input-wrapper input,  {
      @apply bg-white;
    }
    .c-dropdown-input {
      &__options-container, &__entry {
        @apply bg-white;
      }
    }
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$sort-bar-start-column: 10;
$section-border: 1px solid $color-neutral-two;

.c-page-header {
  $c: c-page-header;

  &:not(.#{$c}--with-input-section) {
    .#{$c} {
      &__titlebar {
        margin-bottom: 10px;

        &:only-child {
          border-bottom: 0;
        }
      }
    }
  }

  &__content {
    align-items: center;
    padding: 10px 0;

    .c-input-wrapper {
      margin-bottom: 0;

      .c-input-label {
        margin-bottom: 0;
      }
    }

    @include breakpoint(small) {
      & > div:first-child:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__searchbar {
    grid-column-start: 1;
    grid-column-end: span 6;

    @include breakpoint(small) {
      grid-column-end: span 12;
    }
  }

  &__sortbar {
    grid-column-start: $sort-bar-start-column;
    grid-column-end: span 3;

    @include breakpoint(small) {
      grid-column-start: 1;
      grid-column-end: span 12;
    }
  }

  &__linksbar {
    margin-top: -10px;
    margin-bottom: 5px;
  }

  &__titlebar {
    padding: 10px 0 5px 0;

    h1 {
      margin: 0;
      word-break: break-word;
    }
  }
}

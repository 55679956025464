
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.checkout-page {
  @apply w-[100%] h-[100%] max-w-[1200px] flex flex-col;
  margin: 0 auto;
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.lock-input {
  .c-input__container {
    @apply relative;

    input {
      @apply pr-[30px];
    }
  }

  .lock-icon {
    @apply absolute top-[14px] right-2;
  }
}


          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-header {
  background-color: $color-neutral-one;

  &__container {
    height: 50px;
    max-width: $global-width;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include globalOuterPadding();
    position: relative;
  }

  &__background {
    position: absolute;
    background-color: $color-neutral-one;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &__logo {
    color: $white;
    width: 126px;
    height: 30px;
    z-index: 2;
  }

  &__nav {
    font-weight: $font-weight-regular;
    z-index: 0;

    @include breakpoint(large) {
      color: $white;
      display: flex;
      align-items: center;
    }

    @include breakpoint(medium_down) {
      background-color: transparentize($color-neutral-one, 0.1);
      right: 0;
      top: 50px;
      overflow: hidden;
      position: absolute;
      transform: translate(0, -100%);
      transform-origin: top;
      transition: all 0.5s;
      padding: 5px 0 12px 0;
      visibility: hidden;
    }

    @include breakpoint(medium) {
      width: 33%;
    }

    @include breakpoint(small) {
      width: 100%;
    }

    &.active {
      @include breakpoint(medium_down) {
        transform: translate(0, 0%);
        transition: transform 0.5s;
        max-height: max-content;
        overflow-y: auto;
        height: calc(100vh - 50px);
        visibility: visible;
      }
    }
  }

  &__nav-toggle {
    cursor: pointer;
    z-index: 2;
    height: 50px;
    font-size: $font-size-icon-default;
    line-height: 30px;
    padding: 10px 0;
    &:before {
      color: $white;
    }
  }

  &__nav-item {
    color: $white;
    display: block;
    cursor: pointer;
    position: relative;
    font-size: $font-size-medium;

    @include breakpoint(large) {
      margin-left: 24px;
      height: 20px;
      line-height: 20px;
    }

    @include breakpoint(medium_down) {
      height: 40px;
      padding: 9px 0 11px 15px;
    }

    &:hover,
    &:focus {
      @include breakpoint(medium) {
        text-decoration: underline;
      }
      @include breakpoint(small) {
        text-decoration: underline;
      }
      &:before {
        @include breakpoint(large) {
          position: absolute;
          content: "";
          width: 100%;
          bottom: -6px;
          height: 2px;
          background-color: $white;
        }
      }
    }
    &:hover {
      @include breakpoint(medium_down) {
        background-color: transparentize($color-neutral-one, 0.1);
      }
    }
  }

  &__nav-button {
    margin-left: 10px;
    height: 40px;
    min-width: 150px;
    border-radius: 1px;

    @include breakpoint(medium_down) {
      margin: 10px 15px 0 15px;
    }

    & .c-button {
      min-height: 40px;
      max-height: 40px;
      line-height: 10px;
    }
  }

  &__country {
    height: 33px;
    width: 33px;
    margin: 9px 8px 8px 14px;
    cursor: pointer;
    display: block;

    @include breakpoint(medium_down) {
      margin: 12px 0 0 14px;
    }
  }
}

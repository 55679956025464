
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.c-bottom-menu {
  background-color: $color-background-bottom-nav-hover;
  padding: 15px 0;
  &.selected {
    text-decoration: underline;
  }
  &__link {
    display: block;
    font-size: $font-size-small;
    padding: 13px 20px;
    transition: all 0.2s ease-in-out;
    color: $color-neutral-one;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 320px;
    overflow: hidden;
    &:hover {
      text-decoration: underline;
    }
  }
}

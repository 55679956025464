
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$checkbox-padding-horizontal: 10px;
$checkbox-padding-vertical: 8px;
$checkbox-margin-horizontal: -$checkbox-padding-horizontal;
$checkbox-margin-vertical: -$checkbox-padding-vertical;

@include defaultCheckboxStyles;

.c-checkbox-formik {
  display: flex;

  &__wrapper {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    @include breakpoint(small) {
      font-size: $font-size-medium;
    }
  }

  &__additionalMargin {
    margin: $checkbox-margin-vertical $checkbox-margin-horizontal;
  }

  & .c-input-wrapper {
    margin-bottom: 0;
  }

  input {
    width: 100%;
    height: calc(100% - 3px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;

    &:checked + .c-checkbox-formik__box:before {
      background-color: $color-background-checkbox-selected;
    }

    &:focus + .c-checkbox-formik__box {
      outline: 2px solid $blueFocus;
    }
  }

  &__box {
    width: $checkbox-size-actual;
    height: $checkbox-size-actual;
    flex: 0 0 $checkbox-size-actual;
    display: inline-block;
    position: relative;
    border: $checkbox-border-thickness solid $color-border-checkbox-default;
    background-color: $color-background-checkbox-default;

    &--error {
      border: 1px solid $cardinal-red;
    }

    &--white {
      background-color: $white;
    }

    &:before {
      content: " ";
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
    }

    &--disabled {
      background-color: $color-neutral-three;
      border: $checkbox-border-thickness solid $medium-grey-3;
    }
  }

  &__box--half-checked {
    &:before {
      background: $mint-green;
      top: 9px;
      left: 3px;
      bottom: 9px;
      right: 3px;
    }
  }

  &__label {
    margin-left: 14px;
    word-break: break-word;

    &--disabled {
      color: $color-neutral-two;
      font-weight: $font-weight-regular;
    }
  }

  &__label-postfix {
    &--disabled {
      color: $color-neutral-two;
      font-weight: $font-weight-regular;
    }
  }

  &__annex {
    align-self: flex-start;
    padding-left: 5px;
    margin-left: auto;
    margin-top: $checkbox-border-thickness;
  }

  &--disabled {
    & label {
      cursor: default;
    }
  }
}

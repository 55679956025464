
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
@import "../../GlobalStyles/scrollbar";

$max-dropdown-height: 305px;
$search-input-height: 70px;
$dropdownInput: c-dropdown-input;

.#{$dropdownInput} {
  position: relative;

  &__options-container {
    min-width: 100%;
    max-width: 100vw;
    max-height: 0;
    position: absolute;
    top: 100%;
    overflow-y: hidden;
    background-color: $color-background-item-default;
    z-index: $dropdown-zindex;

    @include breakpoint(small) {
      width: 100%;
    }

    &--open-down {
      border: 1px solid $color-border-input-default;
      max-height: $max-dropdown-height;
      transition: max-height 0.3s cubic-bezier(0.58, 0.56, 1, 0.52);
      display: flex;
      flex-direction: column;
    }

    &--open-up {
      border: 1px solid $color-border-input-default;
      max-height: $max-dropdown-height;
      transition: max-height 0.3s cubic-bezier(0.58, 0.56, 1, 0.52);
      top: auto;
      bottom: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__options {
    @include removeDefaultListStyling;
    max-height: $max-dropdown-height;
    min-width: 90px;
    padding: 0;
    position: relative;

    @include breakpoint(small) {
      width: 100%;
    }

    @include styled-scrollbar;

    &--scrollable {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  &__entry {
    cursor: pointer;
    color: $color-neutral-one;
    padding: 0 19px;
    min-height: 50px;
    height: auto;
    display: flex;
    width: 100%;
    align-items: center;
    white-space: pre;
    background-color: $color-background-item-default;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    @include breakpoint(small) {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    &:hover,
    &.active {
      background-color: $color-background-item-accent-default;
      outline: none;
    }

    &--disabled {
      background-color: $color-neutral-three;
      color: $color-neutral-two;
      pointer-events: none;
      cursor: auto;
    }

    &--bold {
      font-weight: 700;
    }

    &__name {
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &--wrapped {
        white-space: normal;
      }
    }
  }

  &__arrows {
    display: none;
  }

  &--select {
    &.g-font-icon-spotlighticon {
      @mixin entry-style {
        padding-right: 15px;
        padding-left: 15px;
        font-size: 20px;
        @include breakpoint(medium) {
          padding-right: 20%;
          padding-left: 20%;
        }
      }

      .c-input input,
      .#{$dropdownInput}__entry {
        @include entry-style;
        @media (max-width: 900px) and (min-width: $width-device-medium-lower) {
          text-align: center;
        }
      }

      .#{$dropdownInput}__arrows {
        right: 15px;
        @include breakpoint(medium) {
          right: 15%;
        }
        @include breakpoint(medium) {
          right: 15%;
        }
        @media (max-width: 900px) and (min-width: $width-device-medium-lower) {
          display: none;
        }
      }
    }

    .c-dropdown-input__arrows {
      display: block;
      position: absolute;
      bottom: 14px;
      right: 20px;
      cursor: pointer;
    }

    .c-input input {
      cursor: pointer;
      padding-right: 40px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &::placeholder {
        color: inherit;
      }
    }
  }

  &--medium {
    .#{$dropdownInput}__arrows {
      bottom: 9px;
    }
  }

  &__section-label {
    padding: 3px 19px;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    line-height: 1.5;
    background-color: $color-background-dropdown-section-label;
    outline: 0;
    pointer-events: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__search {
    padding: 10px 12px;
    position: relative;

    .c-input-label {
      display: none;
    }

    .c-input-wrapper {
      margin: 0;

      input {
        background-color: $color-background-input-secondary;

        &::placeholder {
          color: $color-text-disabled;
        }
      }

      .g-bg-secondary & {
        input {
          background-color: $color-background-input-default;
        }
      }

      .g-bg-tertiary & {
        input {
          background-color: $color-background-input-default;
        }
      }
    }
  }

  &--disabled {
    .c-dropdown-input__arrows {
      opacity: 0.5;
      cursor: default;
    }
  }

  &--truncate-options {
    .#{$dropdownInput}__options-container {
      width: 100%;
    }
  }

  &--not-truncate-options-sm {
    .#{$dropdownInput}__options-container {
      @include breakpoint(small) {
        width: auto;
      }
    }
  }

  &--with-search-input {
    .#{$dropdownInput} {
      &__options-container--open {
        max-height: $max-dropdown-height + $search-input-height;
      }
    }
  }

  &__relative-container {
    .c-input__container {
      position: relative;
    }
  }

  &--with-search-icon {
    input {
      padding-left: 35px;
    }
  }

  &--with-remove-search-text-icon {
    input {
      padding-right: 35px;
    }
  }
}

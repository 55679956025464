
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
$line-animation-duration: 0.7s;

$burgerIcon: c-burger-icon;

.#{$burgerIcon} {
  width: 40px;
  height: 40px;
  position: relative;

  &__line {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: $white;

    & + & {
      margin-top: 8px;
    }
  }

  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;

    &.#{$burgerIcon}__box--open .#{$burgerIcon}__line {
      &:nth-child(1) {
        animation: ease $line-animation-duration burger-to-cross-top forwards;
      }
      &:nth-child(2) {
        animation: ease $line-animation-duration unscale forwards;
      }
      &:nth-child(3) {
        animation: ease $line-animation-duration burger-to-cross-bottom forwards;
      }
    }

    &:not(.#{$burgerIcon}__box--open) .#{$burgerIcon}__line {
      &:nth-child(1) {
        animation: ease $line-animation-duration cross-to-burger-top forwards;
      }
      &:nth-child(2) {
        animation: ease $line-animation-duration scale forwards;
      }
      &:nth-child(3) {
        animation: ease $line-animation-duration cross-to-burger-bottom forwards;
      }
    }

    &:not(.#{$burgerIcon}__box--animated) .#{$burgerIcon}__line {
      animation-duration: 0s !important;
    }
  }
}

$verticalSpacing: 10px;

@keyframes burger-to-cross-top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: $verticalSpacing;
    transform: rotate(0);
  }
  100% {
    top: $verticalSpacing;
    transform: rotate(45deg);
  }
}

@keyframes cross-to-burger-top {
  0% {
    top: $verticalSpacing;
    transform: rotate(45deg);
  }
  50% {
    top: $verticalSpacing;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes burger-to-cross-bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: $verticalSpacing;
    transform: rotate(0);
  }
  100% {
    bottom: $verticalSpacing;
    transform: rotate(135deg);
  }
}

@keyframes cross-to-burger-bottom {
  0% {
    bottom: $verticalSpacing;
    transform: rotate(135deg);
  }
  50% {
    bottom: $verticalSpacing;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes unscale {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

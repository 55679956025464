
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
@import "../../../../GlobalStyles/scrollbar";

$audio-track-border-color: $color-neutral-two;
$audio-track-list-bg: $color-neutral-one;

.c-audio-track-list {
  border-bottom: 1px solid $audio-track-border-color;
  overflow-y: auto;
  background-color: $audio-track-list-bg;

  @include styled-scrollbar($light-grey-2);
}

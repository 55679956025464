
          
          @import "~component-library/src/patternlib-styles/app-mixins-and-vars.scss";
        
          @import "src/app/styles/common";
        
.my-details-page {
  .section-title {
    @apply text-[23px] font-medium mb-5;
  }
}
